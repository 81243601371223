import React from 'react'
import Skeleton from "react-loading-skeleton"

const CardSkeletons = (props) => {
  const {
    basic,
    num,
    type = ' carousel',
    horizontal // Card layout
  } = props

  // origin code
  // const cardWidth = `column ${num == 1 || type == 'list' ?
  const cardWidth = `column ${num == 1 || horizontal == true ?
    `is-12-desktop is-12-tablet`
    : `is-4-desktop is-4-tablet`
    } is-12-mobile`

  const imageWidth = horizontal
    ? `is-4`
    : `is-12`

  const contentWidth = horizontal
    ? `is-8`
    : `is-12`


  const cardClass = `sing-card o-${horizontal ? 'horizontal' : 'vertical'} ${basic ? 's-basic' : ''} loading`

  let cards = Array.from({ length: num }, (e, i) => {
    return <li
      key={`${i}_postCard`}
      className={cardWidth} >
      <div className={cardClass}>
        <div className="sing-card-inner">
          <div className="columns is-mobile is-gapless" style={{ display: horizontal ? "flex" : "block" }}>
            <div className={`column image is-flex-tablet ${imageWidth}`}>
              <Skeleton
                className="sing-card-image"
                style={{
                  width: "100%",
                  paddingBottom: horizontal ? "56.25%" : "",
                  borderRadius: "0",
                  height: horizontal ? "12rem" : "",
                  marginBottom: "-3rem"
                }} />
            </div>
            <div className={`column text ${contentWidth}`}>
              <div className="sing-card-bottom">
                <span className="category">
                  <Skeleton />
                </span>
                <h5 className="title">
                  <Skeleton />
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  })

  return (
    <ul className="columns overflow-hidden loading rows is-multiline list" id="post-list-container" style={{ maxHeight: "50rem" }}>
      {cards}
    </ul>
  )
}

CardSkeletons.defaultProps = {
  num: 3,
  horizontal: true,
  basic: true
}

export default CardSkeletons
